import "jquery-zoom";

document.addEventListener('DOMContentLoaded', init);

function init() {
  const image = document.querySelector('[data-sharp-product-image]');
  const thumbs = document.querySelectorAll('[data-sharp-product-thumbnail');

  [...thumbs].forEach(thumb => {
    const largeImage = thumb.getAttribute('data-src')
    const hugeImage = thumb.getAttribute('data-src-zoom')

    thumb.addEventListener('click', event => {
      $(thumbs).removeClass('is-active');
      event.currentTarget.classList.add('is-active');
      image.setAttribute('src-zoom', hugeImage);
      image.setAttribute('src', largeImage);
    })
  })
  if(image) {
    $(image.parentNode).zoom({ url: image.getAttribute('src-zoom') });
  }
}
