document.addEventListener('DOMContentLoaded', function() {
  [...document.querySelectorAll('[data-booking-container]')]
    .forEach(container => init(container));
});

function init(node) {
  const addAttendeeBtn = node.querySelector('[data-booking-attendee-add]');

  if (addAttendeeBtn) {
    const attendeeCount = addAttendeeBtn.dataset.attendeeCount;
    const maxAttendees = addAttendeeBtn.dataset.maxAttendees;

    addAttendeeBtn.addEventListener('click', handleAddAttendee);
    if (attendeeCount === maxAttendees) {
      addAttendeeBtn.setAttribute('disabled', true);
      addAttendeeBtn.classList.add('sc-hide');
    };

    [...node.querySelectorAll('[data-booking-attendee]')].forEach(node => {
      listenForRadioChange(node);
      [...node.querySelectorAll('[data-booking-attendee-edit]')].map(btn => {
        btn.addEventListener('click', handleEditAttendee);
      });
      [...node.querySelectorAll('[data-booking-attendee-cancel]')].map(btn => {
        btn.addEventListener('click', event => {
          handleCancelEdit(event, attendeeCount, maxAttendees);
        });
      });
    });
  }
}

function handleAddAttendee(event) {
  const btn = event.currentTarget;
  const container = btn.closest('[data-booking-container]');
  const addBtn = container.querySelector('[data-booking-attendee-add]');
  const template = container.querySelector('[data-booking-template]');

  addBtn.setAttribute('disabled', true);

  if (!!template) {
    template.removeAttribute('data-booking-template');
    enableDisabledInputs(template);
    template.classList.remove('hide');
    container.insertBefore(template, btn);
    listenForRadioChange(template);
  }
}

function enableDisabledInputs (node) {
  const disabledInputs = node.querySelectorAll('input[disabled]');

  [...disabledInputs].forEach(input => input.removeAttribute('disabled'));
}

function handleEditAttendee(event) {
  const attendee = event.currentTarget.closest('[data-booking-attendee]');
  const attendeeForm = attendee.querySelector('[data-booking-attendee-form]')
  const attendeeCard = attendee.querySelector('[data-booking-attendee-card]');
  const container = attendee.closest('[data-booking-container]');
  const addBtn = container.querySelector('[data-booking-attendee-add]');
  const forms = container.querySelectorAll('[data-booking-attendee-form]');
  const cards = container.querySelectorAll('[data-booking-attendee-card]');

  addBtn.setAttribute('disabled', true);

  [...cards].map(card => {
    card === attendeeCard ?
      card.classList.add('hide') :
      card.classList.remove('hide');
  });
  [...forms].map(form => {
    form === attendeeForm ?
      form.classList.remove('hide') :
      form.classList.add('hide');
  });
}

function handleCancelEdit(event, attendeeCount, maxAttendees) {
  const container = event.currentTarget.closest('[data-booking-attendee]');
  const form = container.querySelector('[data-booking-attendee-form]');
  const card = container.querySelector('[data-booking-attendee-card]');
  const addBtn = container.closest('[data-booking-container]').querySelector('[data-booking-attendee-add]');

  if (attendeeCount < maxAttendees) {
    addBtn.removeAttribute('disabled');
  }
  card.classList.remove('hide');
  form.classList.add('hide');
}

function listenForRadioChange(node) {
  const radios = node.querySelectorAll('[data-booking-same-as-order-contact]');
  const fields = node.querySelector('[data-booking-attendee-fields]');
  const inputs = node.querySelectorAll('[data-booking-attendee-input]');

  [...radios].forEach(radio => {
    radio.addEventListener('click', event => {
      if (event.currentTarget.value === "true") {
        fields.classList.add('hide');
        [...inputs].map(input => input.setAttribute('value', ''));
      } else {
        fields.classList.remove('hide');
      }
    });

    if (!!radio['checked']) { radio.click(); }
  });
}
