import 'store_connect/packs/theme';

import "ion-rangeslider";
import "jquery";
import "hammerjs";

import "custom/product-images";

window.currencyFormat = function (elem) {
  var inputVal = $(elem).val();
  var convertedVal = parseFloat(inputVal).toFixed(2);
  $(elem).val(convertedVal);
}

window.setToDate = function(elem) {
  var fromVal = $(elem).val();
  $('input#to_date').attr("min", fromVal)
}

window.upCase = function (elem) {
  var inputVal = $(elem).val();
  var convertedVal = inputVal.toUpperCase();
  $(elem).val(convertedVal);
}

var spinnerRequests = [];

window.spinnerStart = function(keydown) {
  var currentId = new Date().getTime();
  spinnerRequests.push(currentId);
  $('.spinner_container').addClass('active').off('keydown');
  if (typeof keydown === 'function') {
    $(document).off('keydown.spinner_container').on('keydown.spinner_container', function (event) {
      if (event.key === 'Escape') {
        keydown();
        console.log('User stops the process by pressing ESC.')
        spinnerStop(currentId);
      }
    });
  }
  return currentId;
}

window.spinnerStop = function(currentId) {
  spinnerRequests.splice(spinnerRequests.indexOf(currentId), 1);
  if (spinnerRequests.length == 0) {
    $('.spinner_container').removeClass('active');
  }
}

window.talendCheck = function(element, url, ajaxOptions, options) {
  options = options || {};
  // console.log(options);
  // console.log(options.errorText);
  ajaxOptions = ajaxOptions || {};

  var retry = 0;
  var $element = $(element);
  var $errorElement = $(options.errorElement || '.SC-Notice-alert');
  var retryCount = options.retryCount || 10;
  var interval = options.interval || 800;
  var errorText = options.errorText || 'Could not connect to the server.';
  var reset = options.reset || function () { $element.html(''); $errorElement.html('') };
  var callback = options.callback || function (data) { $element.html(data) };
  var skip = options.skip;

  var currentTimeoutId;
  var spinnerId = spinnerStart(function () {
    clearTimeout(currentTimeoutId);
  });

  ajaxOptions.url = url;
  ajaxOptions.type = ajaxOptions.type || 'GET';
  ajaxOptions.cache = false;

  reset();
  var checkForResult = function () {
    $.ajax(ajaxOptions).done(function (data) {
      if (!skip) { spinnerStop(spinnerId); }
      callback(data, spinnerId);
    }).fail(function (error) {
      if (++retry < retryCount) {
        currentTimeoutId = setTimeout(checkForResult, interval * retry * 2);
      } else {
        spinnerStop(spinnerId);
        $errorElement.html(errorText);
      }
    });
  }

  currentTimeoutId = setTimeout(checkForResult, interval);
}

window.check_all = function(namespace) {
  namespace = namespace || '';
  $(document).off('change' + namespace + ':all_checkbox').on('change' + namespace + ':all_checkbox', ':checkbox.all', function () {
    var now = $(this).prop('checked'),
      $form = $(this).parents('form');
    $(this).prop('checked', now);
    $(':checkbox:not(.all)', $form).prop('checked', now)
  });

  $(document).off('change' + namespace + ':checkbox').on('change' + namespace + ':checkbox', ':checkbox:not(.all)', function () {
    var $form = $(this).parents('form');
    $(':checkbox.all', $form).prop('checked', $(':checkbox:not(.all)').map(function () {
      return $(this).prop('checked');
    }).toArray().every(function (condition) { return condition; }));
  });

  $(document).find(':checkbox:not(.all):first').trigger('change' + namespace + ':checkbox');
}

window.initMap = function () {
  var myLatLng = {lat: -25.363, lng: 131.044};

  var map = new google.maps.Map(document.getElementById("js-map"), {
    zoom: 4,
    streetViewControl: false,
    center: myLatLng
  });
}
