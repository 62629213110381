import 'ion-rangeslider'
import { mediumAndUp } from './viewport'

$(function() {
  $('[data-js-range-slider]').ionRangeSlider({
    onFinish: function (data) {
      $('[data-js-range-min]').val(data.from);
      $('[data-js-range-max]').val(data.to);

      if (!mediumAndUp()) { return; }
      $('[data-filters]').submit();
    },
  });
});
